import { call, put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { addDays, format } from "date-fns";
import egam from "../../services/egam";
import { getClientData, isCampanhaSolar } from "../../services/auth";
import { CondicoesDeParcelamentoFixo } from "../../services/condicoesParcelamento";

import { Creators as ParcelamentoActions } from "../ducks/parcelamento";

export function* getParcelamento() {
  const items = yield select((state) => state.order.data.items);

  yield put(ParcelamentoActions.resetParcelamento());

  if (isCampanhaSolar.get()) {
    yield put(ParcelamentoActions.getPercelamentoSolar());
    yield put(ParcelamentoActions.calculateMinimum());
    return;
  }

  if (!items.find((item) => item.mercadoria.id_generico === "N")) {
    yield put(ParcelamentoActions.getPercelamentoGenerico());
    yield put(ParcelamentoActions.calculateMinimum());
    return;
  }
  yield put(ParcelamentoActions.calculateMinimum());
  yield put(ParcelamentoActions.getParcelamentoNormal());
}

export function* getParcelamentoNormal() {
  try {
    const clientData = getClientData();
    const { cd_cliente: cliente } = clientData;
    const totals = yield select((state) => state.order.totals);
    const minimum = yield select((state) => state.parcelamento.minimum);

    if (totals.totalNumber < minimum) {
      return;
    }

    const formData = new FormData();
    formData.append("cliente", cliente);
    formData.append("pedidoExterno", true);
    formData.append("totalLiquido", totals.totalNumber.toFixed(2));
    formData.append("totalMedicamentos", totals.medicamentosNumber.toFixed(2));
    formData.append("totalPerfumaria", totals.perfumariaNumber.toFixed(2));

    const response = yield call(
      egam.post,
      "pedidos/finalizar/parcelamento",
      formData
    );

    if (response.data && response.data.msg_err) {
      toast.error(response.data.msg_err);
      yield put(
        ParcelamentoActions.getPercelamentoFailure(response.data.msg_err)
      );
    } else if (response.data && response.data.parcelas == null) {
      toast.error("Ocorreu um erro ao buscar as opção de parcelamento.");
      yield put(
        ParcelamentoActions.getPercelamentoFailure(
          "Ocorreu um erro ao buscar as opção de parcelamento."
        )
      );
    } else {
      yield put(ParcelamentoActions.getPercelamentoSuccess(response.data));
    }
  } catch (err) {
    yield put(
      ParcelamentoActions.getParcelamentoFailure(
        "Não foi possível buscar as condições de parcelamento"
      )
    );
  }
}

export function* setParcelas(parcelas) {
  yield put(ParcelamentoActions.setParcelas(parcelas));
}

export function* getIntervalo() {
  try {
    const clientData = getClientData();
    const { cd_cliente: cliente } = clientData;
    const totals = yield select((state) => state.order);
    const parcelamento = yield select((state) => state.parcelamento);

    const formData = new FormData();
    formData.append("cliente", cliente);
    formData.append("pedidoExterno", true);
    formData.append("totalLiquido", totals.totals.totalNumber.toFixed(2));
    formData.append(
      "totalMedicamentos",
      totals.totals.medicamentosNumber.toFixed(2)
    );
    formData.append(
      "totalPerfumaria",
      totals.totals.perfumariaNumber.toFixed(2)
    );
    formData.append("NR_PARCELAS", parcelamento.parcela);

    const response = yield call(
      egam.post,
      "pedidos/finalizar/parcelamento/parcelas",
      formData
    );
    if (response.data && response.data.msg_err) {
      toast.error(response.data.msg_err);
      yield put(
        ParcelamentoActions.getParcelamentoFailure(response.data.msg_err)
      );
    } else {
      yield put(ParcelamentoActions.getIntervaloSuccess(response.data));
    }
  } catch (err) {
    yield put(
      ParcelamentoActions.getParcelamentoFailure(
        "Não foi possível buscar as condições de parcelamento"
      )
    );
  }
}

export function* setIntervalos(intervalo) {
  yield put(ParcelamentoActions.setIntevalos(intervalo));
}

export function* getPrazoMedio() {
  try {
    const clientData = getClientData();
    const { cd_cliente: cliente } = clientData;
    const totals = yield select((state) => state.order);
    const parcelamento = yield select((state) => state.parcelamento);

    const formData = new FormData();
    formData.append("cliente", cliente);
    formData.append("pedidoExterno", true);
    formData.append("totalLiquido", totals.totals.totalNumber.toFixed(2));
    formData.append(
      "totalMedicamentos",
      totals.totals.medicamentosNumber.toFixed(2)
    );
    formData.append(
      "totalPerfumaria",
      totals.totals.perfumariaNumber.toFixed(2)
    );
    formData.append("NR_PARCELAS", parcelamento.parcela);
    formData.append("QT_DIAS_INTERVALO", parcelamento.intervalo);

    const response = yield call(
      egam.post,
      "pedidos/finalizar/parcelamento/intervalo",
      formData
    );
    if (response.data && response.data.msg_err) {
      toast.error(response.data.msg_err);
      yield put(
        ParcelamentoActions.getParcelamentoFailure(response.data.msg_err)
      );
    } else {
      yield put(ParcelamentoActions.getPrazoMedioSuccess(response.data));
    }
  } catch (err) {
    yield put(
      ParcelamentoActions.getParcelamentoFailure(
        "Não foi possível buscar as condições de parcelamento"
      )
    );
  }
}

export function* setPrazoMedio(prazoMedio) {
  yield put(ParcelamentoActions.setPrazoMedio(prazoMedio));
}

export function* getCondicoes() {
  try {
    const clientData = getClientData();
    const { cd_cliente: cliente } = clientData;
    const totals = yield select((state) => state.order);
    const parcelamento = yield select((state) => state.parcelamento);

    const formData = new FormData();
    formData.append("cliente", cliente);
    formData.append("pedidoExterno", true);
    formData.append("totalLiquido", totals.totals.totalNumber.toFixed(2));
    formData.append(
      "totalMedicamentos",
      totals.totals.medicamentosNumber.toFixed(2)
    );
    formData.append(
      "totalPerfumaria",
      totals.totals.perfumariaNumber.toFixed(2)
    );
    formData.append("NR_PARCELAS", parcelamento.parcela);
    formData.append("QT_DIAS_INTERVALO", parcelamento.intervalo);
    formData.append("PZ_MEDIO", parcelamento.prazo);

    const response = yield call(
      egam.post,
      "pedidos/finalizar/parcelamento/prazoMedio",
      formData
    );
    if (response.data && response.data.msg_err) {
      toast.error(response.data.msg_err);
      yield put(
        ParcelamentoActions.getParcelamentoFailure(response.data.msg_err)
      );
    } else {
      yield put(ParcelamentoActions.getCondicoesSuccess(response.data));
    }
  } catch (err) {
    yield put(
      ParcelamentoActions.getParcelamentoFailure(
        "Não foi possível buscar as condições de parcelamento"
      )
    );
  }
}

export function* calculaParcelas() {
  const total = yield select((state) => state.order.totals.totalNumber);
  const qtdDias = yield select((states) => states.parcelamento.qtdDias);
  const parcelamentoEspecial = yield select(
    (states) => states.parcelamento.parcelamentoEspecial
  );
  const codigoParcelamento = yield select(
    (states) => states.parcelamento.codigoParcelamento
  );
  const parcelamentoSolar = isCampanhaSolar.get();

  if (qtdDias) {
    const nrDias = Number(qtdDias);
    yield put(
      ParcelamentoActions.calculateParcelamentoGenerico([
        {
          parcela: 1,
          valor: total.toFixed(2).replace(".", ","),
          vencimento: format(addDays(new Date(), nrDias), "dd/MM/yyyy"),
        },
      ])
    );
  } else if (codigoParcelamento) {
    let condicoes = CondicoesDeParcelamentoFixo.genericos;
    if (parcelamentoEspecial) {
      condicoes = Object.values(CondicoesDeParcelamentoFixo.especial).flat();
    }
    if (parcelamentoSolar) {
      condicoes = Object.values(CondicoesDeParcelamentoFixo.solar).flat();
    }
    const condicao = condicoes.find(
      (c) => c.cdParcela.toString() === codigoParcelamento.toString()
    );
    const nrParcelas = condicao.dias.length;
    const parcelas = total / nrParcelas;

    let resto = total % nrParcelas;
    if (resto > 0) {
      const rr = Number((resto / nrParcelas).toFixed(2)) * nrParcelas;
      resto -= rr;
    }
    const condicoesParcela = [];
    for (let i = 0; i < nrParcelas; i += 1) {
      condicoesParcela.push({
        parcela: i + 1,
        valor: (parcelas + resto).toFixed(2).replace(".", ","),
        vencimento: format(addDays(new Date(), condicao.dias[i]), "dd/MM/yyyy"),
      });
    }
    yield put(
      ParcelamentoActions.calculateParcelamentoGenerico(condicoesParcela)
    );
  } else {
    yield put(ParcelamentoActions.calculateParcelamentoGenerico([]));
  }
}

export function* calculateMinimum() {
  const parcelamentoSolar = isCampanhaSolar.get();
  const parcelamentoGenericos = yield select(
    (state) => state.parcelamento.parcelamentoGenericos
  );
  const clientData = getClientData();
  let vlrMinimo =
    parcelamentoSolar ||
    (parcelamentoGenericos && clientData.cd_empresa === 105)
      ? 500
      : 600;
  if (
    parcelamentoSolar &&
    [100, 104, 113, 114].includes(Number(clientData.cd_empresa))
  ) {
    vlrMinimo = 600;
  }
  if (
    parcelamentoSolar &&
    clientData.cd_logged_project === 1 &&
    new Date().valueOf() >= 1730689200000 && // 04/11/2024
    new Date().valueOf() < 1735700400000 // 31/12/2024
  ) {
    vlrMinimo = 1000;
  }

  yield put(ParcelamentoActions.setMinimum(vlrMinimo));
}
